import {
  SET_DETAIL_MATA_KULIAH_START,
  SET_DETAIL_MATA_KULIAH_SUCCESS,
  SET_DETAIL_MATA_KULIAH_FAIL,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setDetailMataKuliahStart = () => {
  return {
    type: SET_DETAIL_MATA_KULIAH_START,
  };
};

// Function untuk get detail mata kuliah
export const getDetailMataKuliah =
  ({
    page = 1,
    size = 10,
    search = "",
    university_code = "",
    faculty_uuid = "",
    major_uuid = "",
    course_name = "",
  }) =>
  async (dispatch) => {
    dispatch(setDetailMataKuliahStart());
    let url;
    if (search !== "") {
      url = `/aptisi/class?search=${search}&page=${page}&page_size=${size}`;
    } else {
      url = `/aptisi/class?university_code=${university_code}&faculty_uuid=${faculty_uuid}&major_uuid=${major_uuid}&course_name=${course_name}&page=${page}&page_size=${size}`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      dispatch({
        type: SET_DETAIL_MATA_KULIAH_SUCCESS,
        payload: res.data.data,
        current_page: page,
        has_previous_page: res.data.has_previous_page,
        has_next_page: res.data.has_next_page,
        previous_page: res.data.previous_page,
        next_page: res.data.next_page,
        last_page: res.data.last_page,
        total_item: res.data.total_item,
      });
    } catch (error) {
      dispatch({
        type: SET_DETAIL_MATA_KULIAH_FAIL,
        payload: error.response.data.message,
      });
      dispatch(setAlert("Can't get data class!", "error"));
    }
  };
