import {
  SHOW_REKOMENDASI_START,
  SHOW_REKOMENDASI_SUCCESS,
  SHOW_REKOMENDASI_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

// get rekomendasi kelas
export const getShowRekomendasi = () => async (dispatch) => {
  // set loading
  dispatch({
    type: SHOW_REKOMENDASI_START,
  });
  try {
    // get data
    const response = await axios.get(`/api/class/recommended?limit=6`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SHOW_REKOMENDASI_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    // Handling error
    dispatch({
      type: SHOW_REKOMENDASI_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data rekomendasi", "error"));
  }
};
