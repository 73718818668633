import { ASSESSMENT_START, ASSESSMENT_FAIL, ASSESSMENT_SUCCESS } from './types';
import axios from '../../../api/axios-orders';
import { setAlert } from '../alert';

// Get data penilaian mata kuliah
export const getAssessment = (class_id) => async (dispatch) => {
  // Action Loading
  dispatch({ type: ASSESSMENT_START });
  try {
    // Get data
    const response = await axios.get(`/student/class/${class_id}/assessment`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    // Finishing data
    dispatch({ type: ASSESSMENT_SUCCESS, payload: response.data.data });
  } catch (error) {
    // Handling Error
    dispatch({ type: ASSESSMENT_FAIL });
    dispatch(setAlert("Something wen't wrong", 'error'));
  }
};
