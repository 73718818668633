import {
  SHOW_BANNER_START,
  SHOW_BANNER_FAIL,
  SET_SHOW_BANNER,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

// set loading
const showBannerStart = (state, action) => {
  return updateObject(state, { loading: true });
};

// get success
const setShowBanner = (state, action) => {
  return updateObject(state, { data: action.payload, loading: false });
};

// get fail
const showBannerFail = (state, action) => {
  return updateObject(state, { error: action.payload, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BANNER_START:
      return showBannerStart(state, action);
    case SET_SHOW_BANNER:
      return setShowBanner(state, action);
    case SHOW_BANNER_FAIL:
      return showBannerFail(state, action);
    default:
      return state;
  }
};

export default reducer;
