/*
 // Page Detail Class
 */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import AOS from 'aos';
import 'aos/dist/aos.css';

// components
import { Header, TableMateri } from './component';

// Global Components
import { LoadKeranjang } from '../Components/Skeleton';
import { LoadText } from '../../../components/Skeleton';

// Actions
import {
  getDetailPerkuliahaan,
  getDetailPerWeek,
  addKeranjang,
} from '../../../store/actions/mahasiswa';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  containerTextDesc: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  containerTextDetail: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(5),
    },
  },
  textDesc: {
    fontWeight: 'bold',
  },
  cardDesc: {
    marginTop: theme.spacing(1),
  },
  btn: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  btnSecond: {
    fontWeight: 'bold',
    textTransform: 'none',
    marginLeft: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  rating: {
    marginRight: theme.spacing(0.7),
  },
}));

const MatakuliahDetail = ({
  auth,
  keranjang,
  detailPerkuliahan,
  getDetailPerkuliahaan,
  getDetailPerWeek,
  addKeranjang,
}) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  AOS.init();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    // open dialog
    setOpen(true);
  };

  const handleClose = () => {
    // close dialog
    setOpen(false);
  };

  const handleCloseAndPush = () => {
    // close the dialog and redirect to home
    history.push('/');
    setOpen(false);
  };

  useEffect(() => {
    // get data detail class and data RPS every params.id changed
    getDetailPerkuliahaan(params.id);
    getDetailPerWeek(params.id);
  }, [params.id]);

  return (
    <Container className={classes.root}>
      <div data-aos="fade-up" data-aos-duration="800">
        {detailPerkuliahan.detail.loading ? (
          <LoadKeranjang />
        ) : (
          <Header
            user={auth.role}
            data={detailPerkuliahan.detail}
            addKeranjang={addKeranjang}
            openDialog={handleClickOpen}
            keranjang={keranjang}
          />
        )}
      </div>
      <Box display="flex" alignItems="flex-end">
        <Rating
          value={detailPerkuliahan.detail.data?.rating}
          readOnly
          className={classes.rating}
        />
        <Typography>{`${detailPerkuliahan.detail.data?.rating} (${detailPerkuliahan.detail.data?.rating_by} Mahasiswa)`}</Typography>
      </Box>
      <div
        className={classes.containerTextDesc}
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <Typography variant="h4" className={classes.textDesc}>
          Deskripsi Perkuliahan
        </Typography>
      </div>
      <Card
        className={classes.cardDesc}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <CardContent>
          {detailPerkuliahan.detail.loading ? (
            Array(5)
              .fill('a')
              .map((_, index) => <LoadText key={index} />)
          ) : (
            <Typography variant="body1" align="justify">
              {detailPerkuliahan.detail.data.description === ''
                ? 'Deskripsi Mata Kuliah tidak tersedia'
                : detailPerkuliahan.detail.data.description}
            </Typography>
          )}
        </CardContent>
      </Card>
      <div
        className={classes.containerTextDetail}
        data-aos="fade-up"
        data-aos-duration="2500"
      >
        <Grid container justify="space-between" alignItems="flex-end">
          <div>
            <Typography variant="h4" className={classes.textDesc}>
              Detail Materi
            </Typography>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnSecond}
              onClick={() =>
                window.open(`${detailPerkuliahan.detail.data.rps}`, '_blank')
              }
              disabled={detailPerkuliahan.detail.data.rps === ''}
            >
              Cetak RPS
            </Button>
          </div>
        </Grid>
      </div>
      <div
        className={classes.table}
        data-aos="fade-up"
        data-aos-duration="2500"
      >
        <TableMateri data={detailPerkuliahan.table} />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Notifikasi Keranjang Anda'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Kelas yang ada pilih berhasil dimasukkan kedalam keranjang, Silahkan
            melakukan checkout sebelum perkuliahan penuh
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAndPush}
            color="secondary"
            variant="outlined"
          >
            Ambil Kelas Lain
          </Button>
          <Button
            onClick={() => history.push('/keranjang')}
            color="primary"
            autoFocus
            variant="contained"
          >
            Lihat Keranjang
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  detailPerkuliahan: state.detailPerkuliahan,
  keranjang: state.keranjang,
});

export default connect(mapStateToProps, {
  getDetailPerkuliahaan,
  getDetailPerWeek,
  addKeranjang,
})(MatakuliahDetail);
