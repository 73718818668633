/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Typography, Grid, Button } from "@material-ui/core";
import LinesEllipsis from "react-lines-ellipsis";

// parent compoenents
import StyledMenu from "../StyledMenu";

const useStyles = makeStyles((theme) => ({
  subMenuRoot: {
    width: "100%",
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #efefef",
    alignItems: "center",
    cursor: "pointer",
  },
  subMenuImageRoot: {
    width: "50px",
    height: "50px",
    marginLeft: theme.spacing(1),
  },
  subMenuImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  subMenuText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "250px",
  },
  subMenuTitle: {
    fontSize: "14px",
  },
  textMessage: {
    fontSize: "12px",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
    fontFamily: "Montserrat",
  },
  emptyData: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  readed: {
    width: "20px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#0763c4",
  },
  gray: {
    opacity: 0.5,
  },
}));

const message = (status) => {
  // check status perkuliahan
  if (status === "waiting") {
    return "Menunggu persetujuan Program Studi asal dan Program Studi tujuan";
  }
  if (status === "approved") {
    return "Selamat Program Studi pilihan anda telah disetujui oleh kedua kampus";
  }
  if (status === "rejected") {
    return "Maaf Program Studi pilihan anda tidak mendapatkan persetujuan";
  }
  if (status === "expired") {
    return "Maaf Program studi pilihan anda sudah kadaluarsa, silahkan pilih kelas kembali";
  }
  if (status === "finished") {
    return "Mata kuliah anda sudah selesai, silakan berikan rating dan komentar";
  }
};

const MenuNotifikasi = ({ anchorEl, id, open, onClose, data, read }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirect = () => {
    // redirect to page notifikasi
    history.push("/notifikasi");
    onClose();
  };

  const redirectRiwayat = (transaction_uuid, id) => {
    // handling to redirent detail riwayat and ubah status notif menjadi read
    const readItem = { notification: [{ notification_id: id }] };
    read(readItem);
    history.push(`/riwayat/${transaction_uuid}`);
    onClose();
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id={id}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {data.data.map((value, index) => {
        if (index < 5) {
          return (
            <div key={index + 1}>
              <div
                className={classes.subMenuRoot}
                onClick={() =>
                  redirectRiwayat(value.transaction_uuid, value.id)
                }
              >
                <div className={classes.subMenuImageRoot}>
                  <img
                    src={
                      value.image !== "" ? value.image : value.university_logo
                    }
                    className={clsx(
                      classes.subMenuImage,
                      value.read_at !== "" && classes.gray
                    )}
                  />
                </div>
                <div className={classes.subMenuText}>
                  <Typography
                    variant="h6"
                    className={clsx(
                      classes.subMenuTitle,
                      value.read_at !== "" && classes.gray
                    )}
                    noWrap
                  >
                    {value.course_name}
                  </Typography>
                  <LinesEllipsis
                    className={clsx(
                      classes.textMessage,
                      value.read_at !== "" && classes.gray
                    )}
                    text={message(value.status)}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </div>
                <div className={classes.readed}>
                  {value.read_at === "" && <div className={classes.dot} />}
                </div>
              </div>
            </div>
          );
        }
      })}
      {data.data.length > 5 && (
        <MenuItem>
          <Grid container justify="center">
            <Button fullWidth color="primary" onClick={redirect}>
              Lihat semua
            </Button>
          </Grid>
        </MenuItem>
      )}
      {data.data.length === 0 && (
        <div className={classes.emptyData}>
          <Typography variant="body1" align="center">
            Tidak ada notifikasi
          </Typography>
        </div>
      )}
    </StyledMenu>
  );
};

export default React.memo(MenuNotifikasi);
