/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Typography, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// parent compoenents
import StyledMenu from "../StyledMenu";

const useStyles = makeStyles((theme) => ({
  subMenuRoot: {
    width: "100%",
    display: "flex",
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #efefef",
    alignItems: "center",
    cursor: "pointer",
  },
  subMenuImageRoot: {
    width: "50px",
    height: "50px",
    marginLeft: theme.spacing(1),
  },
  subMenuImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  subMenuText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "270px",
  },
  subMenuTitle: {
    fontSize: "14px",
  },
  subMenuBody: {
    fontSize: "12px",
  },
  emptyData: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MenuRiwayat = ({ anchorEl, id, open, onClose, data }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToRiwayat = () => {
    history.push("/riwayat");
    onClose();
  };

  const redirectToDetail = (uuid) => {
    history.push(`/riwayat/${uuid}`);
    onClose();
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id={id}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {data.data.map((value, index) => {
        if (index < 5) {
          return (
            <div key={index + 1}>
              <div
                className={classes.subMenuRoot}
                onClick={() => redirectToDetail(value.uuid)}
              >
                <div className={classes.subMenuImageRoot}>
                  <img
                    src={
                      value.image !== "" ? value.image : value.university_logo
                    }
                    className={classes.subMenuImage}
                  />
                </div>
                <div className={classes.subMenuText}>
                  <Typography
                    variant="h6"
                    className={classes.subMenuTitle}
                    noWrap
                  >
                    {value.course_name}
                  </Typography>
                  <Typography variant="body1" className={classes.subMenuBody}>
                    {value.university_name}
                  </Typography>
                </div>
              </div>
            </div>
          );
        }
      })}
      {data.data.length >= 5 && (
        <MenuItem>
          <Grid container justify="center">
            <Button fullWidth color="primary" onClick={redirectToRiwayat}>
              Lihat semua
            </Button>
          </Grid>
        </MenuItem>
      )}
      {data.data.length === 0 && (
        <div className={classes.emptyData}>
          <Typography variant="body1" align="center">
            Riwayat Kosong
          </Typography>
        </div>
      )}
    </StyledMenu>
  );
};

export default React.memo(MenuRiwayat);
