import {
  SHOW_UNIVERSITAS_START,
  SHOW_UNIVERSITAS_SUCCESS,
  SHOW_UNIVERSITAS_FAIL,
} from "./types";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

// get list univeristas
export const getShowUniversitas = (page, pageSize) => async (dispatch) => {
  // set loading
  dispatch({
    type: SHOW_UNIVERSITAS_START,
  });
  try {
    // get data
    const response = await axios.get(
      `/api/university/cooperation?page=${page}&page_size=${pageSize}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch({
      type: SHOW_UNIVERSITAS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    // handling error
    dispatch({
      type: SHOW_UNIVERSITAS_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data universitas", "error"));
  }
};
