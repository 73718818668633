import {
  SHOW_UNIVERSITAS_START,
  SHOW_UNIVERSITAS_SUCCESS,
  SHOW_UNIVERSITAS_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

// set loading
const showUniversitasStart = (state, action) => {
  return updateObject(state, { loading: true });
};

// get success
const showUniversitasSuccess = (state, action) => {
  return updateObject(state, { data: action.payload, loading: false });
};

// get fail
const showUniversitasFail = (state, action) => {
  return updateObject(state, { error: action.payload, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_UNIVERSITAS_START:
      return showUniversitasStart(state, action);
    case SHOW_UNIVERSITAS_SUCCESS:
      return showUniversitasSuccess(state, action);
    case SHOW_UNIVERSITAS_FAIL:
      return showUniversitasFail(state, action);
    default:
      return state;
  }
};

export default reducer;
