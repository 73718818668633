import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RouteWithLayout } from "./components";
import { connect } from "react-redux";

// Layouts
import {
  MainAdmin as MainAdminLayout,
  MainMahasiswa as MainMahasiswaLayout,
  MainAptisi as MainAptisiLayout,
  MainProdi as MainProdiLayout,
  Login,
  LoginAdmin,
  Error404,
} from "./layouts";

// Admin / Prodi / Aptisi Views
import {
  DashboardAdmin as DashboardAdminView,
  DashboardProdi as DashboardProdiView,
  DashboardAptisi as DashboardAptisiView,
  ManageBanner as ManageBannerView,
  ManageArtikel as ManageArtikelView,
  ManageUser as ManageUserView,
  TambahArtikel as TambahArtikelView,
  EditArtikel as EditArtikelView,
  DetailMataKuliahAdmin as DetailMataKuliahAdminView,
  DetailMataKuliahAptisi as DetailMataKuliahAptisiView,
  DetailJumlahMahasiswaAdmin as DetailJumlahMahasiswaAdminView,
  DetailJumlahMahasiswaAptisi as DetailJumlahMahasiswaAptisiView,
  LaporanDataTransaksi as LaporanDataTransaksiView,
  ProdiPenyedia as ProdiPenyediaView,
  ProdiPengambil as ProdiPengambilView,
  MataKuliah as ProdiMataKuliahView,
  MataKuliahUpload as ProdiMataKuliahUploadView,
  Universitas as UniversitasView,
  ProfileProdi as ProfileProdiView,
  DetailJumlahMahasiswaProdi as DetailJumlahMahasiswaProdiView,
  GantiPasswordAdmin as GantiPasswordAdminView,
  GantiPasswordAptisi as GantiPasswordAptisiView,
} from "./views";

// Mahasiswa Views
import {
  Artikel as ArtikelView,
  ArtikelDetail as ArtikelDetailView,
  Keranjang as KeranjangView,
  MainPage as MainPageView,
  Matakuliah as MatakuliahView,
  MatakuliahDetail as MatakuliahDetailView,
  Profile as ProfileView,
  Rating as RatingView,
  Riwayat as RiwayatView,
  RiwayatDetail as RiwayatDetailView,
  Notifikasi as NotifikasiView,
} from "./views";

const Routes = ({ auth }) => {
  // Basic Routes
  let routes = (
    <Switch>
      <Redirect exact from="/" to="/login" />

      <Route exact path="/login" component={Login} />
      <Route exact path="/login-admin" component={LoginAdmin} />
      <Route exact path="/login-aptisi" component={LoginAdmin} />
      <Redirect to="/login" />
    </Switch>
  );

  if (auth.role !== null) {
    // Admin Routes
    if (auth.role === "admin") {
      routes = (
        <Switch>
          <Redirect exact from="/" to="/admin" />

          <RouteWithLayout
            component={DashboardAdminView}
            layout={MainAdminLayout}
            path="/admin"
            exact
          />

          <RouteWithLayout
            component={ManageBannerView}
            layout={MainAdminLayout}
            path="/admin/manage-banner"
            exact
          />

          <RouteWithLayout
            component={ManageUserView}
            layout={MainAdminLayout}
            path="/admin/manage-user"
            exact
          />

          <RouteWithLayout
            component={ManageArtikelView}
            layout={MainAdminLayout}
            path="/admin/manage-artikel"
            exact
          />

          <RouteWithLayout
            component={TambahArtikelView}
            layout={MainAdminLayout}
            path="/admin/tambah-artikel"
            exact
          />

          <RouteWithLayout
            component={EditArtikelView}
            layout={MainAdminLayout}
            path="/admin/edit-artikel/:slug"
            exact
          />

          <RouteWithLayout
            component={DetailMataKuliahAdminView}
            layout={MainAdminLayout}
            path="/admin/detail-mata-kuliah"
            exact
          />

          <RouteWithLayout
            component={DetailJumlahMahasiswaAdminView}
            layout={MainAdminLayout}
            path="/admin/detail-jumlah-mahasiswa/:uuid"
            exact
          />

          <RouteWithLayout
            component={LaporanDataTransaksiView}
            layout={MainAdminLayout}
            path="/admin/laporan-data-transaksi"
            exact
          />

          <RouteWithLayout
            component={UniversitasView}
            layout={MainAdminLayout}
            path="/admin/sync"
            exact
          />

          <RouteWithLayout
            component={GantiPasswordAdminView}
            layout={MainAdminLayout}
            path="/admin/profile"
            exact
          />

          <Redirect exact from="/login" to="/login-admin" />
          <Redirect exact from="/login-aptisi" to="/login-admin" />

          <Route exact path="/login-admin" component={LoginAdmin} />

          <Route to="/not-found" component={Error404} />
        </Switch>
      );
    }
    // Prodi Routes
    if (auth.role === "prodi") {
      routes = (
        <Switch>
          <Redirect exact from="/" to="/prodi" />

          <RouteWithLayout
            component={DashboardProdiView}
            layout={MainProdiLayout}
            path="/prodi"
            exact
          />

          <RouteWithLayout
            component={ProdiPenyediaView}
            layout={MainProdiLayout}
            path="/prodi/prodi-penyedia"
            exact
          />

          <RouteWithLayout
            component={ProdiMataKuliahView}
            layout={MainProdiLayout}
            path="/prodi/mata-kuliah"
            exact
          />

          <RouteWithLayout
            component={ProdiMataKuliahUploadView}
            layout={MainProdiLayout}
            path="/prodi/mata-kuliah/:uuid"
            exact
          />

          <RouteWithLayout
            component={ProdiPengambilView}
            layout={MainProdiLayout}
            path="/prodi/prodi-pengambil"
            exact
          />

          <RouteWithLayout
            component={ProfileProdiView}
            layout={MainProdiLayout}
            path="/prodi/profile"
            exact
          />

          <RouteWithLayout
            component={DetailJumlahMahasiswaProdiView}
            layout={MainProdiLayout}
            path="/prodi/detail-jumlah-mahasiswa/:uuid"
            exact
          />

          <Redirect exact from="/login-aptisi" to="/login" />
          <Redirect exact from="/login-admin" to="/login" />

          <Route exact path="/login" component={Login} />

          <Route to="/not-found" component={Error404} />
        </Switch>
      );
    }
    // Aptisi Routes
    if (auth.role === "aptisi") {
      routes = (
        <Switch>
          <Redirect exact from="/" to="/aptisi" />

          <RouteWithLayout
            component={DashboardAptisiView}
            layout={MainAptisiLayout}
            path="/aptisi"
            exact
          />

          <RouteWithLayout
            component={DetailMataKuliahAptisiView}
            layout={MainAptisiLayout}
            path="/aptisi/detail-mata-kuliah"
            exact
          />

          <RouteWithLayout
            component={GantiPasswordAptisiView}
            layout={MainAptisiLayout}
            path="/aptisi/profile"
            exact
          />

          <RouteWithLayout
            component={DetailJumlahMahasiswaAptisiView}
            layout={MainAptisiLayout}
            path="/aptisi/detail-jumlah-mahasiswa/:uuid"
            exact
          />

          <Redirect exact from="/login" to="/login-aptisi" />
          <Redirect exact from="/login-admin" to="/login-aptisi" />

          <Route exact path="/login-aptisi" component={LoginAdmin} />

          <Route to="/not-found" component={Error404} />
        </Switch>
      );
    }
    // Mahasiswa Routes
    if (auth.role === "mahasiswa") {
      routes = (
        <Switch>
          {/* Mahasiswa */}

          <RouteWithLayout
            component={MainPageView}
            layout={MainMahasiswaLayout}
            path="/"
            exact
          />
          <RouteWithLayout
            component={MatakuliahView}
            layout={MainMahasiswaLayout}
            path="/perkuliahan"
            exact
          />
          <RouteWithLayout
            component={NotifikasiView}
            layout={MainMahasiswaLayout}
            path="/notifikasi"
            exact
          />
          <RouteWithLayout
            component={MatakuliahDetailView}
            layout={MainMahasiswaLayout}
            path="/perkuliahan/:id"
            exact
          />
          <RouteWithLayout
            component={RatingView}
            layout={MainMahasiswaLayout}
            path="/perkuliahan/:id/rating"
            exact
          />
          <RouteWithLayout
            component={KeranjangView}
            layout={MainMahasiswaLayout}
            path="/keranjang"
            exact
          />
          <RouteWithLayout
            component={RiwayatView}
            layout={MainMahasiswaLayout}
            path="/riwayat"
            exact
          />
          <RouteWithLayout
            component={RiwayatDetailView}
            layout={MainMahasiswaLayout}
            path="/riwayat/:id"
            exact
          />
          <RouteWithLayout
            component={ArtikelView}
            layout={MainMahasiswaLayout}
            path="/artikel"
            exact
          />
          <RouteWithLayout
            component={ArtikelDetailView}
            layout={MainMahasiswaLayout}
            path="/artikel/:slug"
            exact
          />
          <RouteWithLayout
            component={ProfileView}
            layout={MainMahasiswaLayout}
            path="/profile"
            exact
          />

          <Route exact path="/login" component={Login} />

          <Route to="/not-found" component={Error404} />
        </Switch>
      );
    }
    // Guest Routes
    if (auth.role === "guest") {
      routes = (
        <Switch>
          {/* Guest */}

          <RouteWithLayout
            component={MainPageView}
            layout={MainMahasiswaLayout}
            path="/"
            exact
          />
          <RouteWithLayout
            component={MatakuliahView}
            layout={MainMahasiswaLayout}
            path="/perkuliahan"
            exact
          />
          <RouteWithLayout
            component={MatakuliahDetailView}
            layout={MainMahasiswaLayout}
            path="/perkuliahan/:id"
            exact
          />
          <RouteWithLayout
            component={ArtikelView}
            layout={MainMahasiswaLayout}
            path="/artikel"
            exact
          />
          <RouteWithLayout
            component={ArtikelDetailView}
            layout={MainMahasiswaLayout}
            path="/artikel/:slug"
            exact
          />

          <Redirect exact from="/login-admin" to="/login" />
          <Redirect exact from="/login-aptisi" to="/login" />
          <Route exact path="/login" component={Login} />

          <Route to="/not-found" component={Error404} />
        </Switch>
      );
    }
  }

  return routes;
};

const mapStateTopProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateTopProps)(Routes);
