import {
  KERANJANG_START,
  KERANJANG_SUCCESS,
  KERANJANG_FAIL,
  KERANJANG_SEND_LOAD,
  KERANJANG_SEND_FINISH,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  loadingSend: false,
  error: false,
  total: 0,
};

// set loading
const keranjangStart = (state, action) => {
  return updateObject(state, {
    data: [],
    loading: true,
    error: false,
    total: 0,
  });
};

// get success
const keranjangSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    total: action.total,
    loading: false,
  });
};

// get fail
const keranjangFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadingSend: false,
    error: true,
  });
};

// loading send data
const keranjangSendLoad = (state, action) => {
  return updateObject(state, { loadingSend: true });
};

// success send data
const keranjangSendFinish = (state, action) => {
  return updateObject(state, { loadingSend: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case KERANJANG_START:
      return keranjangStart(state, action);
    case KERANJANG_SUCCESS:
      return keranjangSuccess(state, action);
    case KERANJANG_FAIL:
      return keranjangFail(state, action);
    case KERANJANG_SEND_LOAD:
      return keranjangSendLoad(state, action);
    case KERANJANG_SEND_FINISH:
      return keranjangSendFinish(state, action);
    default:
      return state;
  }
};

export default reducer;
