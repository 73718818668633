import {
  PERKULIAHAN_START,
  PERKULIAHAN_SUCCESS,
  PERKULIAHAN_FAIL,
  SET_SEARCH_NAME_PERKULIAHAN,
} from './types';
import axios from '../../../api/axios-orders';
import { setAlert } from '../alert';

// get list perkuliahan
export const getPerkuliahan =
  (
    university_code = '',
    faculty_uuid = '',
    major_uuid = '',
    semester = '',
    search = '',
    latest_class_id = '',
    limit = ''
  ) =>
  async (dispatch) => {
    // set loading
    dispatch({
      type: PERKULIAHAN_START,
    });
    try {
      // get data
      const response = await axios.get(
        `/api/class?university_code=${university_code}&faculty_uuid=${faculty_uuid}&major_uuid=${major_uuid}&semester=${semester}&search=${search}&class=${latest_class_id}&limit=${limit}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch({
        type: PERKULIAHAN_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      // handling Error
      dispatch({
        type: PERKULIAHAN_FAIL,
        payload: error.response.message,
      });
      dispatch(setAlert("Can't get data perkuliahan", 'error'));
    }
  };

  // Action set search by nama perkuliahan
export const setSearch = (name) => {
  return {
    type: SET_SEARCH_NAME_PERKULIAHAN,
    payload: name,
  };
};
