import {
  SET_DATA_CHART_UNIVERSITAS,
  SET_DATA_CHART_PRODI,
  SET_DASHBOARD_CHART_UNIVERSITAS_START,
  SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS,
  SET_DASHBOARD_CHART_UNIVERSITAS_FAIL,
  SET_DASHBOARD_CHART_PRODI_START,
  SET_DASHBOARD_CHART_PRODI_SUCCESS,
  SET_DASHBOARD_CHART_PRODI_FAIL,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";
import fileDownload from "js-file-download";

export const setDashboardChartUniversitasStart = () => {
  return {
    type: SET_DASHBOARD_CHART_UNIVERSITAS_START,
  };
};

export const setDashboardChartProdiStart = () => {
  return {
    type: SET_DASHBOARD_CHART_PRODI_START,
  };
};

// function untuk get data chart universitas yang di butuhkan di dashboard admin
export const getDashboardChartUniversitas = () => async (dispatch) => {
  dispatch(setDashboardChartUniversitasStart());
  // Get api data chart
  try {
    const res = await axios.get(`/aptisi/transaction/chart`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_DATA_CHART_UNIVERSITAS,
      payload: res.data.data,
    });

    // untuk mapping data yang akan ditampilkan di chart
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let borderColor = [];
    res.data.data.forEach(function (e) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      let forLabels = e.university_name;
      let forData = e.total;
      let forBackgroundColor = "#" + randomColor;
      let forBorderColor = "#fff";
      labels.push(forLabels);
      data.push(forData);
      backgroundColor.push(forBackgroundColor);
      borderColor.push(forBorderColor);
    });

    const newData = {
      labels: labels,
      datasets: [
        {
          label: "Jumlah Mahasiswa",
          data: data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    dispatch({
      type: SET_DASHBOARD_CHART_UNIVERSITAS_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    // Handling error
    dispatch({
      type: SET_DASHBOARD_CHART_UNIVERSITAS_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get chart university!", "error"));
  }
};

export const getDashboardChartProdi = (university_code) => async (dispatch) => {
  dispatch(setDashboardChartProdiStart());
  try {
    const res = await axios.get(
      `/aptisi/transaction/university/${university_code}/chart`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch({
      type: SET_DATA_CHART_PRODI,
      payload: res.data.data,
    });

    let labels = [];
    let data = [];
    let backgroundColor = [];
    let borderColor = [];
    res.data.data.forEach(function (e) {
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      let forLabels = e.major_name;
      let forData = e.total;
      let forBackgroundColor = "#" + randomColor;
      let forBorderColor = "#fff";
      labels.push(forLabels);
      data.push(forData);
      backgroundColor.push(forBackgroundColor);
      borderColor.push(forBorderColor);
    });

    const newData = {
      labels: labels,
      datasets: [
        {
          label: "Prodi",
          data: data,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    dispatch({
      type: SET_DASHBOARD_CHART_PRODI_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    dispatch({
      type: SET_DASHBOARD_CHART_PRODI_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data chart major!", "error"));
  }
};

export const printUniversitas = () => async (dispatch) => {
  try {
    const res = await axios.get(`aptisi/export/pdf/transaction/chart `, {
      headers: {
        "Content-Type": `application/pdf`,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      responseType: "blob",
    });
    fileDownload(res.data, "universitas.pdf");
  } catch (error) {
    dispatch(setAlert("Export Failed", "error"));
  }
};

export const printProdi = (university_code) => async (dispatch) => {
  try {
    const res = await axios.get(
      `aptisi/export/pdf/transaction/university/${university_code}/chart `,
      {
        headers: {
          "Content-Type": `application/pdf`,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: "blob",
      }
    );
    fileDownload(res.data, "prodi.pdf");
  } catch (error) {
    dispatch(setAlert("Export Failed", "error"));
  }
};
