import {
  KATEGORI_UNIVERSITAS_START,
  KATEGORI_UNIVERSITAS_SUCCESS,
  KATEGORI_UNIVERSITAS_FAIL,
  KATEGORI_FAKULTAS_START,
  KATEGORI_FAKULTAS_SUCCESS,
  KATEGORI_FAKULTAS_FAIL,
  KATEGORI_PRODI_START,
  KATEGORI_PRODI_SUCCESS,
  KATEGORI_PRODI_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  universitas: {
    data: [],
    loading: false,
    error: false,
  },
  fakultas: {
    data: [],
    loading: false,
    error: false,
  },
  prodi: {
    data: [],
    loading: false,
    error: false,
  },
  semester: {
    data: [
      { id: 1, name: "Semester 1" },
      { id: 2, name: "Semester 2" },
      { id: 3, name: "Semester 3" },
      { id: 4, name: "Semester 4" },
      { id: 5, name: "Semester 5" },
      { id: 6, name: "Semester 6" },
      { id: 7, name: "Semester 7" },
    ],
  },
};

// set loading
const universitasStart = (state, action) => {
  return updateObject(state, {
    universitas: { ...state.universitas, loading: true, error: false },
  });
};

// get success
const universitasSuccess = (state, action) => {
  return updateObject(state, {
    universitas: { data: action.payload, loading: false, error: false },
  });
};

// get fail
const universitasFail = (state, action) => {
  return updateObject(state, {
    universitas: { ...state.universitas, loading: false, error: true },
  });
};

// set loading
const fakultasStart = (state, action) => {
  return updateObject(state, {
    fakultas: { data: [], loading: true, error: false },
  });
};

// get success
const fakultasSuccess = (state, action) => {
  return updateObject(state, {
    fakultas: { data: action.payload, loading: false, error: false },
  });
};

// get fail
const fakultasFail = (state, action) => {
  return updateObject(state, {
    fakultas: { data: [], loading: false, error: true },
  });
};

// set loading
const prodiStart = (state, action) => {
  return updateObject(state, {
    prodi: { data: [], loading: true, error: false },
  });
};

const prodiSuccess = (state, action) => {
  return updateObject(state, {
    prodi: { data: action.payload, loading: false, error: false },
  });
};

const prodiFail = (state, action) => {
  return updateObject(state, {
    prodi: { data: [], loading: false, error: true },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case KATEGORI_UNIVERSITAS_START:
      return universitasStart(state, action);
    case KATEGORI_UNIVERSITAS_SUCCESS:
      return universitasSuccess(state, action);
    case KATEGORI_UNIVERSITAS_FAIL:
      return universitasFail(state, action);
    case KATEGORI_FAKULTAS_START:
      return fakultasStart(state, action);
    case KATEGORI_FAKULTAS_SUCCESS:
      return fakultasSuccess(state, action);
    case KATEGORI_FAKULTAS_FAIL:
      return fakultasFail(state, action);
    case KATEGORI_PRODI_START:
      return prodiStart(state, action);
    case KATEGORI_PRODI_SUCCESS:
      return prodiSuccess(state, action);
    case KATEGORI_PRODI_FAIL:
      return prodiFail(state, action);
    default:
      return state;
  }
};

export default reducer;
