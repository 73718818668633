// import library
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  CircularProgress,
  Box,
  Backdrop,
} from "@material-ui/core";

import ImageIcon from "@material-ui/icons/Image";

import { Dropzone, DialogAlert, DialogUploadBanner } from "./component";

import { connect } from "react-redux";
import {
  getBanner,
  setAlert,
  createBanner,
  setLoadingUpload,
  setLoadingDone,
} from "../../../store/actions";

// Untuk styling css
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  mb: {
    marginBottom: theme.spacing(2),
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  cardTitle: {
    fontSize: 16,
  },

  cardActions: {
    display: "flex",
    justifyContent: "center",
  },

  btnSave: {
    width: 250,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  input: {
    display: "none",
  },
}));

// functional component ManageBanner
const ManageBanner = ({
  banner,
  getBanner,
  setAlert,
  createBanner,
  setLoadingDone,
  setLoadingUpload,
}) => {
  const classes = useStyles();
  // const [file, setFile] = useState(null);
  // const [reset, setReset] = useState(false);

  // state yang berfungsi untuk membuka dialog
  const [openDialogAlert, setOpenDialogAlert] = useState({
    open: false,
    id: null,
  });
  // state yang berfungsi untuk src profile
  const [srcProfile, setSrcProfile] = useState({ open: false, src: null });

  // const handleChangeBanner = (event) => {
  //   setFile(event[0]);
  // };

  // const onSubmit = async () => {
  //   if (file === null) {
  //     return setAlert("Banner Belum Di Upload", "error");
  //   }
  //   const fileBanner = new FormData();
  //   fileBanner.append("file", file);
  //   fileBanner.append("dir", "banner");
  //   setReset(true);
  //   setFile(null);

  //   try {
  //     const res = await axios.post(`/admin/file/upload `, fileBanner, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Accept: "multipart/form-data",
  //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //       },
  //     });

  //     const formData = {
  //       path: res.data.data,
  //     };

  //     createBanner(formData);
  //   } catch (error) {
  //     setAlert("Banner Gagal Di Upload", "error");
  //   }
  // };

  // Function untuk mengupload image
  const uploaderImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrcProfile({ open: true, src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    // get data banner
    getBanner();
  }, [getBanner]);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Manage Banner
      </Typography>
      {banner.data.length < 5 && (
        // <Card style={{ marginBottom: 20 }}>
        //   <CardHeader
        //     title={
        //       <Typography className={classes.cardTitle}>
        //         Silahkan Upload Banner Anda
        //       </Typography>
        //     }
        //   />
        //   <CardContent>
        //     <Dropzone
        //       handleChangeBanner={handleChangeBanner}
        //       reset={reset}
        //       setReset={setReset}
        //       setFile={setFile}
        //     />
        //   </CardContent>
        //   <CardActions className={classes.cardActions}>
        //     <Button
        //       className={classes.btnSave}
        //       size="large"
        //       variant="contained"
        //       color="primary"
        //       onClick={onSubmit}
        //     >
        //       Save
        //     </Button>
        //   </CardActions>
        // </Card>

        <>
          <input
            accept="image/jpg,image/png,image/jpeg"
            className={classes.input}
            id="contained-button-file"
            type="file"
            name="file"
            onChange={uploaderImage}
          />
          <label htmlFor="contained-button-file">
            <Button
              className={classes.mb}
              variant="contained"
              color="secondary"
              component="span"
            >
              Upload Banner
            </Button>
          </label>
        </>
      )}

      <Card>
        <CardHeader
          title={
            <Typography className={classes.cardTitle}>Daftar Banner</Typography>
          }
        />
        <CardContent>
          {banner.loading ? (
            <Box align="center">
              <CircularProgress />
            </Box>
          ) : banner.data.length > 0 ? (
            <List>
              {banner.data.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Banner ${index + 1}`} />
                    <Button
                      style={{ marginRight: 10 }}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href={item.path}
                    >
                      Lihat Banner
                    </Button>
                    <Button
                      onClick={() =>
                        setOpenDialogAlert({
                          open: true,
                          id: item.id,
                        })
                      }
                      variant="contained"
                      color="inherit"
                    >
                      Hapus
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Box align="center">
              <Typography variant="h5">Data Kosong</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <Backdrop className={classes.backdrop} open={banner.loadingUpload}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogAlert
        dialog={openDialogAlert}
        onClose={() => {
          setOpenDialogAlert({ open: false, id: null });
        }}
      />

      {/* Dialog Upload Banner */}
      <DialogUploadBanner
        dialog={srcProfile}
        onClose={() => setSrcProfile({ open: false, src: null })}
        action={createBanner}
        setAlert={setAlert}
        setLoadingUpload={setLoadingUpload}
        setLoadingDone={setLoadingDone}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    banner: state.banner,
  };
};

export default connect(mapStateToProps, {
  getBanner,
  setAlert,
  createBanner,
  setLoadingUpload,
  setLoadingDone,
})(ManageBanner);
