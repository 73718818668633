/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Typography, Grid, Button } from "@material-ui/core";

// parent compoenents
import StyledMenu from "../StyledMenu";

const useStyles = makeStyles((theme) => ({
  subMenuRoot: {
    width: "100%",
    display: "flex",
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #efefef",
    cursor: "pointer",
  },
  subMenuImageRoot: {
    width: "50px",
    height: "50px",
    marginLeft: theme.spacing(1),
  },
  subMenuImage: {
    width: "50px",
    height: "50px",
    objectFit: "cover",
  },
  subMenuText: {
    width: "270px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  subMenuTitle: {
    fontSize: "14px",
  },
  subMenuBody: {
    fontSize: "12px",
  },
  emptyData: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MenuKeranjang = ({ anchorEl, id, open, onClose, data }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirect = () => {
    history.push("/keranjang");
    onClose();
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id={id}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {data.data.map((value, index) => (
        <div key={index + 1}>
          <div className={classes.subMenuRoot} onClick={redirect}>
            <div className={classes.subMenuImageRoot}>
              <img
                src={value.image !== "" ? value.image : value.university_logo}
                className={classes.subMenuImage}
              />
            </div>
            <div className={classes.subMenuText}>
              <Typography variant="h6" className={classes.subMenuTitle} noWrap>
                {value.course_name}
              </Typography>
              <Typography
                variant="body1"
                className={classes.subMenuBody}
                noWrap
              >
                {value.university_name}
              </Typography>
            </div>
          </div>
        </div>
      ))}
      {data.data.length !== 0 && (
        <MenuItem>
          <Grid container justify="center">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={redirect}
            >
              lihat keranjang
            </Button>
          </Grid>
        </MenuItem>
      )}
      {data.data.length === 0 && (
        <div className={classes.emptyData}>
          <Typography variant="body1" align="center">
            Keranjang Kosong
          </Typography>
        </div>
      )}
    </StyledMenu>
  );
};

export default React.memo(MenuKeranjang);
