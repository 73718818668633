// parent compoenents untuk menu pada navbar
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/core";

const StyledMenu = withStyles({
  paper: {
    width: "350px",
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default StyledMenu;
