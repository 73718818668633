import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";

import CustomRouterLink from "../../../../../components/CustomRouterLink";
import { LoadUniversitas } from "../../../Components/Skeleton";
import LogoProklamasi45 from "../../../../../assets/img/UP45.png";
import LogoUPPBM from "../../../../../assets/img/UPPBM.png";
import LogoEsaUnggul from "../../../../../assets/img/esa-unggul.png";
import LogoUIGM from "../../../../../assets/img/UIGM.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 1, 0),
  },
  card: {
    borderRadius: theme.spacing(1),
  },
  media: {
    height: "100%",
    paddingTop: "100%",
    objectFit: "contain",
  },
  cardContent: {
    height: "100px",
  },
  textPerkuliahan: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "0px",
  },
}));

const ListUniversitas = ({ list }) => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      {list.data.map((value, index) => (
        <Grid
          item
          lg={2}
          md={3}
          sm={4}
          xs={6}
          key={index}
          className={classes.container}
        >
          <Card className={classes.card}>
            <CardActionArea
              component={CustomRouterLink}
              to={`/perkuliahan?universitas=${value.university_cooperation_code}`}
            >
              <CardMedia
                className={classes.media}
                title="Image Perkuliahan"
                image={value.logo}
              />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="body1"
                  className={classes.textPerkuliahan}
                >
                  {value.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      {/* Selesai Demo Di delete aja ini hanya untuk menuh2in konten */}
      <Grid item lg={2} md={3} sm={4} xs={6} className={classes.container}>
        <Card className={classes.card}>
          <CardActionArea
            component={CustomRouterLink}
            to={`https://up45.ac.id/`}
          >
            <CardMedia
              className={classes.media}
              title="Image Perkuliahan"
              image={LogoProklamasi45}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                align="center"
                variant="body1"
                className={classes.textPerkuliahan}
              >
                Universitas Proklamasi 45
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6} className={classes.container}>
        <Card className={classes.card}>
          <CardActionArea
            component={CustomRouterLink}
            to={`https://esaunggul.ac.id/`}
            target="_blank"
          >
            <CardMedia
              className={classes.media}
              title="Image Perkuliahan"
              image={LogoEsaUnggul}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                align="center"
                variant="body1"
                className={classes.textPerkuliahan}
              >
                Universitas Esa Unggul
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6} className={classes.container}>
        <Card className={classes.card}>
          <CardActionArea
            component={CustomRouterLink}
            to={`http://www.pancabudi.ac.id`}
          >
            <CardMedia
              className={classes.media}
              title="Image Perkuliahan"
              image={LogoUIGM}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                align="center"
                variant="body1"
                className={classes.textPerkuliahan}
              >
                Universitas Indo Global Mandiri
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item lg={2} md={3} sm={4} xs={6} className={classes.container}>
        <Card className={classes.card}>
          <CardActionArea
            component={CustomRouterLink}
            to={`http://www.uigm.ac.id/`}
          >
            <CardMedia
              className={classes.media}
              title="Image Perkuliahan"
              image={LogoUPPBM}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                align="center"
                variant="body1"
                className={classes.textPerkuliahan}
              >
                Universitas Pembagunan Panca Budi Medan
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {/* Setelah demo di delete aja */}
      {/* Dummy content */}
      {list.loading &&
        Array(3)
          .fill("1")
          .map((_, index) => (
            <Grid
              item
              lg={2}
              md={3}
              sm={4}
              xs={6}
              key={index}
              className={classes.container}
            >
              <LoadUniversitas />
            </Grid>
          ))}
      {!list.loading && list.data.length === 0 && (
        <Grid
          container
          className={classes.containerEmpty}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Typography align="center">Data tidak tersedia</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(ListUniversitas);
