import {
  KERANJANG_START,
  KERANJANG_SUCCESS,
  KERANJANG_FAIL,
  KERANJANG_SEND_LOAD,
  KERANJANG_SEND_FINISH,
} from './types';
import axios from '../../../api/axios-orders';
import { setAlert } from '../alert';
import { getNotifikasi } from './notifikasi';
import { getRiwayat } from './riwayat';

// Get data Keranjang
export const getKeranjang = () => async (dispatch) => {
  // Set Loading
  dispatch({
    type: KERANJANG_START,
  });
  try {
    const response = await axios.get(`/student/cart`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    // Check data, jika data null return array kosong []
    if (response.data.data === null) {
      dispatch({
        type: KERANJANG_SUCCESS,
        payload: [],
        total: 0,
      });
    } else {
      dispatch({
        type: KERANJANG_SUCCESS,
        payload: response.data.data,
        total: response.data.data.length,
      });
    }
  } catch (error) {
    // Handling Error
    dispatch({
      type: KERANJANG_FAIL,
      // payload: error.response.message,
    });
    dispatch(setAlert("Can't get data cart", 'error'));
  }
};

// Action Tambah Keranjang
export const addKeranjang = (data, openDialog) => async (dispatch) => {
  // Set Loading
  dispatch({
    type: KERANJANG_SEND_LOAD,
  });
  try {
    // Send Data
    await axios.post(`/student/cart`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch({
      type: KERANJANG_SEND_FINISH,
    });

    // get data keranjang terbaru
    dispatch(getKeranjang());
    // Open dialog
    openDialog();
    // Set alert success
    dispatch(setAlert('Success add to cart', 'success'));
  } catch (error) {
    // Handling Error message
    if (error.response.data.message === 'class already in cart') {
      dispatch({
        type: KERANJANG_SEND_FINISH,
      });
      dispatch(setAlert('Kelas sudah ada di keranjang anda', 'error'));
    } else if (
      error.response.data.message === "you've already take the class"
    ) {
      dispatch({
        type: KERANJANG_SEND_FINISH,
      });
      dispatch(setAlert('Anda sudah mengambil kelas ini', 'error'));
    } else {
      dispatch({
        type: KERANJANG_FAIL,
        payload: error.response.message,
      });
      dispatch(setAlert("Can't add item to cart", 'error'));
    }
  }
};

// Action delete keranjang
export const deleteKeranjang = (id) => async (dispatch) => {
  try {
    // send data
    await axios.delete(`/student/cart/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    // get data keranjang terbaru
    dispatch(getKeranjang());

    // set alert success
    dispatch(setAlert('cart item has been deleted', 'success'));
  } catch (error) {
    // Handling error
    dispatch({
      type: KERANJANG_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("something wen't wrong", 'error'));
  }
};

// Action checkout keranjang
export const checkoutKeranjang = (data, openDialog) => async (dispatch) => {
  try {
    // send data
    await axios.post(`/student/transaction/checkout`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    // get data keranjang terbaru
    dispatch(getKeranjang());
    // alert success
    dispatch(setAlert('Success', 'success'));
    // get notifikasi terbaru
    dispatch(getNotifikasi());
    // get history terbaru
    dispatch(getRiwayat());
    // open dialog
    openDialog();
  } catch (error) {
    // Handling Error message
    if (error.response.data.message === 'Kelas Sudah dipilih') {
      dispatch(setAlert('Anda sudah mengambil kelas ini', 'error'));
    } else {
      dispatch(setAlert(error.response.data.message, 'error'));
    }
  }
};
