// Import library
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import Select from "react-select";

import Skeleton from "@material-ui/lab/Skeleton";

import TableUniversitas from "./component/TableUniversitas/TableUniversitas.jsx";
import TableProdi from "./component/TableProdi/TableProdi.jsx";

import { connect } from "react-redux";
import {
  getDashboardChartUniversitas,
  getDashboardChartProdi,
  getFilterUniversitas,
  printUniversitas,
  printProdi,
} from "../../../store/actions";

import { Bar, Doughnut } from "react-chartjs-2";

// Untuk styling css
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },

  cardTitle: {
    fontWeight: 700,
    fontSize: 20,
  },

  cardContent: {
    fontWeight: 400,
    fontSize: 16,
  },

  cardActions: {
    display: "flex",
    justifyContent: "right",
  },
}));

// digunakan untuk options react-chart
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

// functional component untuk Dashboard Admin
const Dashboard = ({
  getDashboardChartUniversitas,
  getDashboardChartProdi,
  dashboard,
  getFilterUniversitas,
  filter,
  printUniversitas,
  printProdi,
}) => {
  // deklarasi classes untuk styling
  const classes = useStyles();
  // state untuk set universitas ketika menggunakan filter
  const [universitas, setUniversitas] = useState("");

  useEffect(() => {
    // get data chart universitas
    getDashboardChartUniversitas();
    // get data untuk filter universitas
    getFilterUniversitas();
  }, [getDashboardChartUniversitas, getFilterUniversitas]);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Perkuliahan MBKM
      </Typography>

      {/* Universitas */}
      <Grid container spacing={2} style={{ marginBottom: 45 }}>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardContent>
              <div className="chartUniversitas">
                <Bar data={dashboard.dataChartUniversitas} options={options} />
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader
              title={
                <Typography className={classes.cardTitle}>
                  Total Peserta MBKM di Perguruan Tinggi
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <TableUniversitas data={dashboard.dataUniversitas} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <div>
                <Button
                  onClick={() => {
                    printUniversitas();
                  }}
                  size="small"
                  variant="contained"
                  color="secondary"
                >
                  Cetak
                </Button>
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Prodi */}
      <Grid container spacing={2} style={{ marginBottom: 45 }}>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardActions>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Select
                    className="basic-single"
                    isSearchable={true}
                    name="color"
                    options={filter.universitas.data}
                    onChange={(e) => {
                      if (e === null) {
                        setUniversitas("");
                      } else {
                        setUniversitas({ id: e.value, code: e.code });
                        getDashboardChartProdi(e.code);
                      }
                    }}
                    placeholder="Pilih Universitas.."
                  />
                </Grid>
              </Grid>
            </CardActions>
            <CardContent>
              {dashboard.loadingChartProdi ? (
                <Skeleton variant="rect" width="100%">
                  <div style={{ paddingTop: "57%" }} />
                </Skeleton>
              ) : (
                <div className="chartProdi">
                  <Typography className={classes.cardTitle}>
                    Prodi Paling Banyak Diambil
                  </Typography>
                  <Doughnut data={dashboard.dataChartProdi} />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Card>
            <CardHeader
              title={
                <Typography className={classes.cardTitle}>
                  Penjelasan Prodi
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <TableProdi data={dashboard.dataProdi} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                onClick={() => {
                  if (universitas !== "") {
                    printProdi(universitas.code);
                  }
                }}
                size="small"
                variant="contained"
                color="secondary"
              >
                Cetak
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Traffic */}
      {/* <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <Line data={dataLine} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              title={
                <Typography className={classes.cardTitle}>
                  Penjelasan Traffic
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <Typography className={classes.cardContent}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Dolorum, ipsam cupiditate doloribus vel deleniti officiis
                molestiae ea alias necessitatibus nisi. Amet iure id
                perspiciatis corporis blanditiis expedita exercitationem soluta
                minus.
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button size="small" variant="contained" color="secondary">
                Cetak
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid> */}
    </Container>
  );
};

// memanggil state dashboard dan filter dari redux
const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboardAdmin,
    filter: state.filterAdmin,
  };
};

// export function Dashboard Admin
export default connect(mapStateToProps, {
  getDashboardChartUniversitas,
  getFilterUniversitas,
  getDashboardChartProdi,
  printUniversitas,
  printProdi,
})(Dashboard);
