/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Button,
  Avatar,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HistoryIcon from "@material-ui/icons/History";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

// compoenent menu /modal
import MenuRiwayat from "./MenuRiwayat";
import MenuKeranjang from "./MenuKeranjang";
import MenuNotifikasi from "./MenuNotifikasi";
import MenuKategori from "./MenuKategori";
import Search from "./Search";

// Image Logo
import Logo from "../../../../assets/img/logo.png";

// actions
import {
  getKategoriUniversitas,
  getKategoriFakultas,
  getKategoriProdi,
  getKeranjang,
  getNotifikasi,
  readNotifikasi,
  getRiwayat,
  setSearch,
} from "../../../../store/actions/mahasiswa";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "auto",
    height: "70px",
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  navbarColor: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
    color: theme.palette.black,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  boxNested: {
    "&.MuiMenu-paper": {
      boxShadow: "none",
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  secondTitle: {
    fontWeight: "bold",
    padding: theme.spacing(0, 3),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.MuiButton-root": {
      textTransform: "none",
    },
    cursor: "pointer",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
}));

function Navbar({
  auth,
  authLogout,
  getKategoriUniversitas,
  getKategoriFakultas,
  getKategoriProdi,
  kategori,
  keranjang,
  getKeranjang,
  getNotifikasi,
  notifikasi,
  readNotifikasi,
  getRiwayat,
  riwayat,
  setSearch,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElRiwayat, setAnchorElRiwayat] = useState(null);
  const [anchorElKeranjang, setAnchorElKeranjang] = useState(null);
  const [anchorElNotifikasi, setAnchorElNotifikasi] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [stateKategori, setStateKategori] = useState({
    fakultas: {
      open: false,
      university_id: null, // Code University
    },
    prodi: {
      open: false,
      faculty_id: null, // Fakulty id
    },
    semester: {
      open: false,
      major_id: null,
    },
  });

  const isMenuProfileOpen = Boolean(anchorElProfile);
  const isMenuRiwayatOpen = Boolean(anchorElRiwayat);
  const isMenuKeranjangOpen = Boolean(anchorElKeranjang);
  const isMenuNotifikasiOpen = Boolean(anchorElNotifikasi);

  const handleProfileMenuOpen = (event) => {
    // open menu profile
    setAnchorElProfile(event.currentTarget);
  };

  const handleRiwayatMenuOpen = (event) => {
    // open menu riwayat
    setAnchorElRiwayat(event.currentTarget);
  };

  const handleKeranjangMenuOpen = (event) => {
    // open menu keranjang
    setAnchorElKeranjang(event.currentTarget);
  };

  const handleNotifikasiMenuOpen = (event) => {
    // open menu notifikasi
    setAnchorElNotifikasi(event.currentTarget);
  };

  const handleMenuProfileClose = () => {
    // close menu profile
    setAnchorElProfile(null);
  };

  const handleMenuRiwayatClose = () => {
    // close menu riwayat
    setAnchorElRiwayat(null);
  };

  const handleMenuKeranjangClose = () => {
    // close menu keranjang
    setAnchorElKeranjang(null);
  };

  const handleMenuNotifikasiClose = () => {
    // close menu notifikasi
    setAnchorElNotifikasi(null);
  };

  const handleKategori = (e) => {
    // handling posisi menu kategory
    if (menuPosition) {
      return;
    }
    e.preventDefault();
    setMenuPosition({
      top: 57,
      left: 153,
    });
  };

  const handleLogout = () => {
    // handling logout
    authLogout();
    localStorage.removeItem("access_token");
    localStorage.removeItem("role");
    localStorage.removeItem("data");
    localStorage.clear();
    history.push("/login");
  };

  // ----------- MENU PROFILE
  const menuId = "primary-search-account-menu";
  const renderMenuProfile = (
    <Menu
      anchorEl={anchorElProfile}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuProfileOpen}
      onClose={handleMenuProfileClose}
    >
      {auth.role !== "guest" && (
        <MenuItem
          onClick={() => {
            history.push("/profile");
            handleMenuProfileClose();
          }}
        >
          Profile
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  useEffect(() => {
    // get data ketegory universitas
    getKategoriUniversitas();

    // jika user merupakan guest maka tidak perlu get data kerjang dan notifikasi
    if (auth.role !== "guest") {
      getKeranjang();
      getNotifikasi();
      getRiwayat();
    }
  }, []);

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.navbarColor}>
        <Toolbar>
          <img
            src={Logo}
            className={classes.logo}
            onClick={() => history.push("/")}
          />
          <Button
            className={classes.secondTitle}
            variant="text"
            onClick={handleKategori}
          >
            Kategori
          </Button>
          <Button
            className={classes.secondTitle}
            variant="text"
            onClick={() => history.push("/artikel")}
          >
            Artikel
          </Button>
          <Search setSearch={setSearch} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-controls="menu-riwayat-id"
              aria-haspopup="true"
              onClick={handleRiwayatMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={0} color="secondary">
                <HistoryIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-controls="menu-keranjang-id"
              aria-haspopup="true"
              onClick={handleKeranjangMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={keranjang.total} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-controls="menu-notifikasi-id"
              aria-haspopup="true"
              onClick={handleNotifikasiMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={notifikasi.total} color="secondary">
                <NotificationsActiveIcon />
              </Badge>
            </IconButton>
            {auth.data !== null && auth.data.picture ? (
              <Avatar
                alt="profile"
                src={auth.data.picture}
                className={classes.avatar}
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              />
            ) : (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {renderMenuProfile}
      <MenuKategori
        menuPosition={menuPosition}
        setMenuPosition={setMenuPosition}
        setStateKategori={setStateKategori}
        stateKategori={stateKategori}
        getKategoriFakultas={getKategoriFakultas}
        getKategoriProdi={getKategoriProdi}
        data={kategori}
      />
      <MenuRiwayat
        anchorEl={anchorElRiwayat}
        id="menu-riwayat-id"
        open={isMenuRiwayatOpen}
        onClose={handleMenuRiwayatClose}
        data={riwayat}
      />
      <MenuKeranjang
        id="menu-keranjang-id"
        anchorEl={anchorElKeranjang}
        open={isMenuKeranjangOpen}
        onClose={handleMenuKeranjangClose}
        data={keranjang}
      />
      <MenuNotifikasi
        id="menu-notifikasi-id"
        anchorEl={anchorElNotifikasi}
        open={isMenuNotifikasiOpen}
        onClose={handleMenuNotifikasiClose}
        data={notifikasi}
        read={readNotifikasi}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  kategori: state.kategori,
  keranjang: state.keranjang,
  notifikasi: state.notifikasi,
  riwayat: state.riwayat,
});

export default connect(mapStateToProps, {
  getKategoriUniversitas,
  getKategoriFakultas,
  getKategoriProdi,
  getKeranjang,
  getNotifikasi,
  readNotifikasi,
  getRiwayat,
  setSearch,
})(React.memo(Navbar));
