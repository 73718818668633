/*
 // Page Profile
 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Card, Grid, CardContent } from '@material-ui/core';

// Components
import { DataProfile, ImageProfile } from './component';

// Global Components
import { Heading } from '../Components';

// Actions
import { getProfile } from '../../../store/actions/mahasiswa';

const useStyles = makeStyles((theme) => ({
  viewRoot: {
    marginTop: theme.spacing(5),
  },
  boxContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  dataContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const Profile = ({ profile, getProfile }) => {
  const classes = useStyles();

  useEffect(() => {
    // get data profile
    getProfile();
  }, [getProfile]);

  return (
    <div>
      <Heading text="Profile" />
      <Container maxWidth="lg" className={classes.viewRoot}>
        <Card>
          <CardContent>
            <Grid container className={classes.boxContainer}>
              <Grid item md={8} xs={12} className={classes.dataContainer}>
                <DataProfile data={profile} />
              </Grid>
              <Grid item md={4} xs={12}>
                <ImageProfile data={profile} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(Profile);
