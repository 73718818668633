/*
 // Page Rating
 */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// utils
import { isEmpty } from '../../../utils';

// actions
import {
  sendRatingKelas,
  getDetailPerkuliahaan,
} from '../../../store/actions/mahasiswa';

// Global Components
import { Heading } from '../Components';

// Components
import { Header } from './component';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(7),
  },
  textQuestion: {
    fontWeight: 'bold',
  },
  containerStar: {
    marginTop: theme.spacing(2),
  },
  star: {
    fontSize: '40px',
  },
  btn: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 7),
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#EE6A0D',
    '&:hover': {
      backgroundColor: '#D63A00',
    },
  },
}));

// Validation form
const validationSchema = yup.object().shape({
  rating: yup.number().typeError('Rating harus diisi'),
});

const RiwayatDetail = ({
  sendRatingKelas,
  getDetailPerkuliahaan,
  auth,
  detailPerkuliahan,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // init func validation
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // Manipulate data and send to api
    const formData = {
      ...data,
      rating: data.rating * 10,
      class_uuid: params.id,
    };
    sendRatingKelas(
      formData,
      params.id,
      history.location.state.transaction_uuid,
      history
    );
  };

  // Check if previous router dont send data, then rediret to home
  if (isEmpty(history.location.state)) {
    history.push('/');
  }

  useEffect(() => {
    // get data detail class
    getDetailPerkuliahaan(params.id);
  }, []);

  return (
    <div>
      <Heading text="Feedback" />
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Header user={auth.role} data={detailPerkuliahan.detail} />
          </div>
          <div className={classes.container}>
            <Typography variant="h4" className={classes.textQuestion}>
              Apakah anda puas dengan Perkuliahan ini ?
            </Typography>
            <div className={classes.containerStar}>
              <Controller
                control={control}
                name="rating"
                defaultValue={0}
                render={({ onChange, value }) => (
                  <Rating
                    className={classes.star}
                    name="simple-controlled"
                    precision={0.5}
                    value={value}
                    onChange={(e, newValue) => onChange(newValue)}
                  />
                )}
              />
            </div>
            <Button variant="contained" className={classes.btn} type="submit">
              Kirim
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  detailPerkuliahan: state.detailPerkuliahan,
});

export default connect(mapStateToProps, {
  sendRatingKelas,
  getDetailPerkuliahaan,
})(RiwayatDetail);
