import {
  SHOW_REKOMENDASI_START,
  SHOW_REKOMENDASI_SUCCESS,
  SHOW_REKOMENDASI_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

// set loading
const showRekomendasiStart = (state, action) => {
  return updateObject(state, { loading: true, data: [] });
};

// get success
const showRekomendasiSuccess = (state, action) => {
  return updateObject(state, { data: action.payload, loading: false });
};

// get fail
const showRekomendasiFail = (state, action) => {
  return updateObject(state, { error: action.payload, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_REKOMENDASI_START:
      return showRekomendasiStart(state, action);
    case SHOW_REKOMENDASI_SUCCESS:
      return showRekomendasiSuccess(state, action);
    case SHOW_REKOMENDASI_FAIL:
      return showRekomendasiFail(state, action);
    default:
      return state;
  }
};

export default reducer;
