/*
 // Page Detail History
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Grid,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@material-ui/core';
import { Skeleton, Rating } from '@material-ui/lab';

// Actions
import {
  getShowRekomendasi,
  getRiwayatDetail,
  getAssessment,
  getProfile,
} from '../../../store/actions/mahasiswa';

// components
import { SelectedItem, CardStepper } from './component';

// Global Components
import { Heading, ListPerkuliahan } from '../Components';
import { LoadKeranjang } from '../Components/Skeleton';

const useStyles = makeStyles((theme) => ({
  stepperBox: {
    marginTop: theme.spacing(4),
  },
  textContainer: {
    marginTop: theme.spacing(10),
  },
  textTitle: {
    fontWeight: '700',
    lineHeight: '24px',
  },
  textLink: {
    fontWeight: '700',
    lineHeight: '24px',
    color: '#0068d3',
    cursor: 'pointer',
  },
  textSubtitle: {
    fontWeight: '500',
    lineHeight: '24px',
  },
  textAssessment: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
}));

const RiwayatDetail = ({
  showRekomendasi,
  getShowRekomendasi,
  riwayat,
  getRiwayatDetail,
  assessment,
  getAssessment,
  getProfile,
  profile,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    // Get data recommend class and profile
    getShowRekomendasi();
    getProfile();
  }, []);

  useEffect(() => {
    // get data detail history event params.id changed
    getRiwayatDetail(params.id);
  }, [params.id]);

  useEffect(() => {
    // check status assessment, if true then get the data
    if (riwayat.detail.data?.status_assessment) {
      getAssessment(riwayat.detail.data.class_uuid);
    }
  }, [riwayat.detail.data]);

  return (
    <div>
      <Heading text="Detail Riwayat" />
      <Container maxWidth="lg">
        {riwayat.detail.loading ? (
          <LoadKeranjang />
        ) : (
          <SelectedItem data={riwayat.detail} />
        )}
        <div className={classes.stepperBox}>
          {riwayat.detail.loading ? (
            <Skeleton variant="rect" width="100%" height={150} />
          ) : (
            <CardStepper data={riwayat.detail} profile={profile.data} />
          )}
        </div>
        {riwayat.detail.data?.status_assessment && (
          <Box marginTop={7} marginBottom={4}>
            <Typography className={classes.textAssessment} align="center">
              Hasil Nilai
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kehadiran</TableCell>
                  <TableCell>Nilai Harian</TableCell>
                  <TableCell>Nilai Praktek</TableCell>
                  <TableCell>UTS</TableCell>
                  <TableCell>UAS</TableCell>
                  <TableCell>Nilai Keseluruhan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{assessment.data?.presence || '-'}</TableCell>
                  <TableCell>
                    {assessment.data?.daily_assessment || '-'}
                  </TableCell>
                  <TableCell>
                    {assessment.data?.practical_assessment || '-'}
                  </TableCell>
                  <TableCell>
                    {assessment.data?.midtest_assessment || '-'}
                  </TableCell>
                  <TableCell>
                    {assessment.data?.finaltest_assessment || '-'}
                  </TableCell>
                  <TableCell>
                    {assessment.data?.assessment_by_number || '-'} /{' '}
                    {assessment.data?.assessment_by_index || '-'} /{' '}
                    {assessment.data?.assessment_by_letter || '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
        {riwayat.detail.data?.status_rating && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={2}
          >
            <Typography>Rating yang anda berikan</Typography>
            <Rating
              value={riwayat.detail.data?.rating / 10}
              precision={0.5}
              readOnly
            />
          </Box>
        )}
        {riwayat.detail.data?.status_assessment && (
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push({
                  pathname: `/perkuliahan/${riwayat.detail.data?.class_uuid}/rating`,
                  state: { transaction_uuid: params.id },
                })
              }
              disabled={riwayat.detail.data?.status_rating}
            >
              Kasih Rating
            </Button>
          </Box>
        )}
        <div className={classes.textContainer}>
          <Typography variant="h4" className={classes.textTitle}>
            Rekomendasi Perkuliahan
          </Typography>
          <Grid container justify="space-between">
            <Typography variant="subtitle2" className={classes.textSubitle}>
              Rekomendasi berdasarkan semester
            </Typography>
            <Typography
              variant="body1"
              className={classes.textLink}
              onClick={() => history.push('/perkuliahan')}
            >
              Lihat Semua
            </Typography>
          </Grid>
        </div>
        <div>
          <ListPerkuliahan list={showRekomendasi} lg={2} md={3} sm={4} xs={6} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showRekomendasi: state.showRekomendasi,
  riwayat: state.riwayat,
  assessment: state.assessment,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getShowRekomendasi,
  getRiwayatDetail,
  getAssessment,
  getProfile,
})(RiwayatDetail);
