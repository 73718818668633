import {
  NOTIFIKASI_START,
  NOTIFIKASI_SUCCESS,
  NOTIFIKASI_FAIL,
} from "../../actions/mahasiswa/types";
import { updateObject } from "../../../utils";

const initialState = {
  data: [],
  loading: false,
  error: false,
  total: 0,
};

// set loading
const notifikasiStart = (state, action) => {
  return updateObject(state, {
    data: [],
    loading: true,
    error: false,
    total: 0,
  });
};

// get success
const notifikasiSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    total: action.total,
    loading: false,
  });
};

// get fail
const notifikasiFail = (state, action) => {
  return updateObject(state, {
    data: [],
    total: 0,
    loading: false,
    error: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFIKASI_START:
      return notifikasiStart(state, action);
    case NOTIFIKASI_SUCCESS:
      return notifikasiSuccess(state, action);
    case NOTIFIKASI_FAIL:
      return notifikasiFail(state, action);

    default:
      return state;
  }
};

export default reducer;
