import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
} from '@material-ui/core';

const step = [
  {
    label: 'Menunggu',
    note: 'Menunggu persetujuan dari program studi asal dan tujuan',
  },
  {
    label: 'Disetujui',
    note: 'Pekuliahan telah disetujui oleh prodi asal dan tujuan',
  },
  {
    label: 'Mulai Kuliah',
    note: 'Selamat menikmati perkuliahan di MBKM',
  },
];

const stepNumber = (status) => {
  if (status === 'waiting') {
    return 0;
  }
  if (status === 'approved') {
    return 3;
  }
  if (status === 'rejected' || status === 'expired' || status === 'finished') {
    return -1;
  }
};

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#C4C4C4',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '& .MuiCardHeader-avatar': {
      marginRight: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  boxTextHeader: {
    padding: theme.spacing(0.5, 10),
    width: '200px',
    backgroundColor: '#EE6A0D',
    borderRadius: '5px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5, 1),
    },
  },
  textHeader: {
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
  },
  textLabelStepper: {
    fontWeight: 'bold',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  containReject: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  stepContain: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1),
    },
  },
  containFinish: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnRating: {
    marginTop: theme.spacing(2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
}));

const CardStepper = ({ data, profile }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {data.data !== null && (
        <Card>
          <CardHeader
            className={classes.header}
            avatar={
              <div className={classes.boxTextHeader}>
                <Typography variant="h4" className={classes.textHeader}>
                  {data.data.status}
                </Typography>
              </div>
            }
          />
          <CardContent className={classes.content}>
            {/* STATUS WAITING OR APPROVED */}
            {(data.data.status === 'waiting' ||
              data.data.status === 'approved') && (
              <Stepper
                alternativeLabel
                activeStep={stepNumber(data.data.status)}
                className={classes.stepContain}
              >
                {step.map((data) => (
                  <Step key={data.label}>
                    <StepLabel>
                      <Typography
                        variant="h5"
                        className={classes.textLabelStepper}
                      >
                        {data.label}
                      </Typography>
                      <Typography variant="body1">{data.note}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}

            {/* STATUS APPROVED */}
            {data.data.status === 'approved' && (
              <div className={classes.btnContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    // window.open(`https://${data.data.website}`, "_blank")
                    profile?.university_name === 'PT. XYZ'
                      ? window.open(
                          `https://up45-re.mandiribelajar.co.id/`,
                          '_blank'
                        )
                      : window.open(
                          `https://demo-lms-mbkm.mandiribelajar.co.id/`,
                          '_blank'
                        )
                  }
                >
                  Menuju E-Learning
                </Button>
              </div>
            )}

            {/* STATUS REJECT */}
            {data.data.status === 'rejected' && (
              <div className={classes.containReject}>
                <Typography variant="body1">{`Mohon maaf perkuliahan yang anda pilih di tolak karena ${data.data.message}`}</Typography>
              </div>
            )}

            {/* STATUS REJECT */}
            {data.data.status === 'expired' && (
              <div className={classes.containReject}>
                <Typography variant="body1">{`Mohon maaf perkuliahan yang anda pilih sudah kadaluarsa, silahkan pilih kelas kembali`}</Typography>
              </div>
            )}

            {/* STATUS FINISHED */}
            {data.data.status === 'finished' && (
              <div className={classes.containFinish}>
                <Typography variant="h3">Matakuliah sudah selesai</Typography>
                {data.data.status_rating ? (
                  <>
                    <Typography
                      className={classes.mt}
                      variant="body1"
                    >{`Silahkan berikan rating dan juga ulasan tentang matakuliah ini`}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnRating}
                      onClick={() =>
                        history.push(
                          `/perkuliahan/${data.data.class_uuid}/rating`,
                          { transaction_uuid: data.data.uuid }
                        )
                      }
                    >
                      Beri rating
                    </Button>
                  </>
                ) : (
                  <Typography
                    className={classes.mt}
                    variant="body1"
                  >{`Terima kasih atas partisipasi dan feedbacknya dalam mengikuti program kuliah merdeka`}</Typography>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default CardStepper;
