import { SET_USER, SET_LOADING, SET_LOADING_DONE } from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

// Function untuk get data user di admin view
export const getUser = (page, page_size, search) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
  try {
    const response = await axios.get(
      `/admin/user?page=${page}&page_size=${page_size}&search=${search}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch({
      type: SET_USER,
      payload: response.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data user", "error"));
  }
};

export const createUser = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
  try {
    await axios.post(`/admin/user`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_LOADING_DONE,
    });
    dispatch(setAlert("User has been created", "success"));
    dispatch(getUser(1, 10));
  } catch (error) {
    dispatch(setAlert("Failed To Create User", "error"));
  }
};

export const updateUser = (data, id) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
  try {
    await axios.put(`/admin/user/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_LOADING_DONE,
    });
    dispatch(setAlert("User has been updated", "success"));
    dispatch(getUser(1, 10));
  } catch (error) {
    dispatch(setAlert("Can't get data user", "error"));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
  try {
    await axios.delete(`/admin/user/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_LOADING_DONE,
    });
    dispatch(setAlert("User has been deleted", "success"));
    dispatch(getUser(1, 10));
  } catch (error) {
    dispatch(setAlert("Can't get data user", "error"));
  }
};

export const searchUser = (name) => async (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
  try {
    const response = await axios.get(`/admin/user?name=${name}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_USER,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data user", "error"));
  }
};
