import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  Divider,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    marginTop: theme.spacing(3),
  },
  image: {
    width: "150px",
    height: "150px",
    borderRadius: "10xp",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 8px auto",
    },
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
  },
  textPerkuliahan: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "29px",
    cursor: "pointer",
  },
  textUniversitas: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 400,
  },
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: "#263238",
  },
  statusContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "300px",
  },
  textStatus: {
    color: "#F8992E",
    fontWeight: "bold",
  },
  textNote: {
    fontSize: "14px",
  },
  emptyData: {
    height: "150px",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnHome: {
    marginTop: theme.spacing(3),
  },
}));

const timeClass = (period) => {
  return `${period.slice(0, 5)}-${period.slice(9, 14)}`;
};

const SelectedItem = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Card className={classes.card}>
        {data.data !== null ? (
          <Grid container>
            <div className={classes.image}>
              <CardMedia
                component="div"
                className={classes.media}
                image={
                  data.data.image !== ""
                    ? data.data.image
                    : data.data.university_logo
                }
              />
            </div>
            <div className={classes.textContainer}>
              <Typography
                varinat="h5"
                className={classes.textPerkuliahan}
                onClick={() =>
                  history.push(`/perkuliahan/${data.data.class_uuid}`)
                }
              >
                {`${data.data.course_name} (${data.data.class_code})`}
              </Typography>
              <Typography varinat="h5" className={classes.textUniversitas}>
                {data.data.university_name}
              </Typography>
              <Typography variant="body1" className={classes.textTime}>
                {data.data.first_class_date}
              </Typography>
              <Typography variant="body1" className={classes.textTime}>
                {timeClass(data.data.period)}
              </Typography>
            </div>
          </Grid>
        ) : (
          <div className={classes.emptyData}>
            <Typography variant="h3">Data tidak ada</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/")}
              className={classes.btnHome}
            >
              Kembali ke Home
            </Button>
          </div>
        )}
      </Card>
      <Divider className={classes.divider} />
    </>
  );
};

export default SelectedItem;
