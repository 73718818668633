import {
  KATEGORI_UNIVERSITAS_START,
  KATEGORI_UNIVERSITAS_SUCCESS,
  KATEGORI_UNIVERSITAS_FAIL,
  KATEGORI_FAKULTAS_START,
  KATEGORI_FAKULTAS_SUCCESS,
  KATEGORI_FAKULTAS_FAIL,
  KATEGORI_PRODI_START,
  KATEGORI_PRODI_SUCCESS,
  KATEGORI_PRODI_FAIL,
} from './types';
import axios from '../../../api/axios-orders';
import { setAlert } from '../alert';

// const timeout = (ms) => {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// };

// Dummy Data
const dummyUniversitas = [
  {
    university_id: 652,
    university_code: '1111A',
    name: 'Universitas Esa Unggul',
  },
  {
    university_id: 762,
    university_code: '1111B',
    name: 'Universitas Budi Luhur',
  },
  {
    university_id: 261,
    university_code: '1111C',
    name: 'Universitas Pancasila',
  },
  {
    university_id: 261,
    university_code: '1111D',
    name: 'Universitas Pembangunan Panca Budi',
  },
  {
    university_id: 182,
    university_code: '1111E',
    name: 'Universitas Janabadra',
  },
  {
    university_id: 182,
    university_code: '1111F',
    name: 'Sekolah Tinggi Manajemen Handayani',
  },
  {
    university_id: 182,
    university_code: '1111G',
    name: 'Sekolah Tinggi Agama Islam Ambon',
  },
  {
    university_id: 182,
    university_code: '1111H',
    name: 'Universitas Pasundan',
  },
  {
    university_id: 182,
    university_code: '1111I',
    name: 'STMIK Asia Malang',
  },
  {
    university_id: 182,
    university_code: '1111J',
    name: 'Universitas Darma Agung',
  },
  {
    university_id: 182,
    university_code: '1111K',
    name: 'Universitas Ichsan Gorontalo',
  },
  {
    university_id: 182,
    university_code: '1111L',
    name: 'IKIP Negeri Gorontalo',
  },
  {
    university_id: 182,
    university_code: '1111M',
    name: 'Universitas Yarsi',
  },
  {
    university_id: 182,
    university_code: '1111N',
    name: 'Universitas Muhammadiyah Sumatra Utara',
  },
];

// Dummy Data
const dummyFakultas = [
  {
    faculty_uuid: 'f4412052-24b5-49da-9419-8d7sa87das',
    name: 'Teknik',
    cooperation_university_id: '39f2fa64-c7a6-41d4-ba79-d9s898sd9s',
  },
];

// Dummy Data
const dummyProdi = [
  {
    major_uuid: '644fcc2b-0cce-4d1a-8248-98d98sjh',
    major_name: 'Teknik Informatika',
    cooperation_university_id: '39f2fa64-c7a6-41d4-ba79-sa98sa8a9',
  },
  {
    major_uuid: '644fcc2b-0cce-4d1a-d9a89d8a9',
    major_name: 'Teknik Industri',
    cooperation_university_id: '39f2fa64-c7a6-41d4-ba79-sa98sa8a9',
  },
  {
    major_uuid: '644fcc2b-0cce-4d1a-da8d9sd78s878d',
    major_name: 'Teknik Elektro',
    cooperation_university_id: '39f2fa64-c7a6-41d4-ba79-sa98sa8a9',
  },
];

// Get Kategory univesitas
export const getKategoriUniversitas = () => async (dispatch) => {
  // Set Loading
  dispatch({
    type: KATEGORI_UNIVERSITAS_START,
  });
  try {
    // Get Data
    const response = await axios.get('/api/navbar/university', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    // Logic Asli
    // dispatch({
    //   type: KATEGORI_UNIVERSITAS_SUCCESS,
    //   payload: response.data.data,
    // });

    // Data asli + data dummy
    const plusData = [...response.data.data, ...dummyUniversitas];
    dispatch({
      type: KATEGORI_UNIVERSITAS_SUCCESS,
      payload: plusData,
    });
  } catch (error) {
    // Handling Error
    dispatch({
      type: KATEGORI_UNIVERSITAS_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data university", 'error'));
  }
};

// Get data Kategory by universitas
export const getKategoriFakultas = (university_code) => async (dispatch) => {
  // Set Loading
  dispatch({
    type: KATEGORI_FAKULTAS_START,
  });
  try {
    // Get data
    const response = await axios.get(
      `/api/navbar/university/${university_code}/faculty`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    // Dummy Logic
    // YANG ELSE YANG BENAR
    if (
      university_code === '1111A' ||
      university_code === '1111B' ||
      university_code === '1111C' ||
      university_code === '1111D' ||
      university_code === '1111E' ||
      university_code === '1111F' ||
      university_code === '1111G' ||
      university_code === '1111H' ||
      university_code === '1111I' ||
      university_code === '1111J' ||
      university_code === '1111K' ||
      university_code === '1111L' ||
      university_code === '1111M' ||
      university_code === '1111N'
    ) {
      dispatch({
        type: KATEGORI_FAKULTAS_SUCCESS,
        payload: dummyFakultas,
      });
    }
    // Logic yang asli
    else {
      dispatch({
        type: KATEGORI_FAKULTAS_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    // Handling error
    dispatch({
      type: KATEGORI_FAKULTAS_FAIL,
      payload: error.response.message,
    });
    dispatch(setAlert("Can't get data faculty", 'error'));
  }
};

// Get kategory by university and fakultas
export const getKategoriProdi =
  (code_university, id_faculty) => async (dispatch) => {
    dispatch({
      type: KATEGORI_PRODI_START,
    });
    try {
      const response = await axios.get(
        `/api/navbar/university/${code_university}/faculty/${id_faculty}/major`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      // Dummy Logic
      if (
        (code_university === '1111A' ||
          code_university === '1111B' ||
          code_university === '1111C' ||
          code_university === '1111D' ||
          code_university === '1111E' ||
          code_university === '1111F' ||
          code_university === '1111G' ||
          code_university === '1111H' ||
          code_university === '1111I' ||
          code_university === '1111J' ||
          code_university === '1111K' ||
          code_university === '1111L' ||
          code_university === '1111M' ||
          code_university === '1111N') &&
        id_faculty === 'f4412052-24b5-49da-9419-8d7sa87das'
      ) {
        dispatch({
          type: KATEGORI_PRODI_SUCCESS,
          payload: dummyProdi,
        });
      }
      // Logic yang asli, jika dummy data tidak diperlukan
      // Silakan pakai logic else
      else {
        dispatch({
          type: KATEGORI_PRODI_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      // Handling Error
      dispatch({
        type: KATEGORI_PRODI_FAIL,
        payload: error.response.message,
      });
      dispatch(setAlert("Can't get data major", 'error'));
    }
  };
