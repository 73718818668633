import {
  SET_DETAIL_JUMLAH_MAHASISWA_START,
  SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS,
  SET_DETAIL_JUMLAH_MAHASISWA_FAIL,
  SET_DETAIL_JUMLAH_MAHASISWA_CHART_START,
  SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS,
  SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL,
} from "./actionTypes";
import axios from "../../../api/axios-orders";
import { setAlert } from "../alert";

export const setDetailJumlahMahasiswaStart = () => {
  return {
    type: SET_DETAIL_JUMLAH_MAHASISWA_START,
  };
};

export const setDetailJumlahMahasiswaChartStart = () => {
  return {
    type: SET_DETAIL_JUMLAH_MAHASISWA_CHART_START,
  };
};

// Function get data detail jumlah mahasiswa
export const getDetailJumlahMahasiswa =
  ({ page = 1, size = 10, search = "", uuid }) =>
  async (dispatch) => {
    dispatch(setDetailJumlahMahasiswaStart());
    try {
      const res = await axios.get(
        `/aptisi/class/${uuid}/detail?search=${search}&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      dispatch({
        type: SET_DETAIL_JUMLAH_MAHASISWA_SUCCESS,
        payload: res.data.data,
        current_page: page,
        has_previous_page: res.data.has_previous_page,
        has_next_page: res.data.has_next_page,
        previous_page: res.data.previous_page,
        next_page: res.data.next_page,
        last_page: res.data.last_page,
        total_item: res.data.total_item,
      });
    } catch (error) {
      dispatch({
        type: SET_DETAIL_JUMLAH_MAHASISWA_FAIL,
        payload: error.response.data.message,
      });
      dispatch(setAlert("Can't get data detail class!", "error"));
    }
  };

export const getDetailJumlahMahasiswaChart =
  ({ uuid }) =>
  async (dispatch) => {
    dispatch(setDetailJumlahMahasiswaChartStart());
    try {
      const res = await axios.get(`/aptisi/class/${uuid}/chart`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      let labels = [];
      let data = [];
      let backgroundColor = [];
      let borderColor = [];
      res.data.data.forEach(function (e) {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        let forLabels = e.university_name;
        let forData = e.total;
        let forBackgroundColor = "#" + randomColor;
        let forBorderColor = "#fff";
        labels.push(forLabels);
        data.push(forData);
        backgroundColor.push(forBackgroundColor);
        borderColor.push(forBorderColor);
      });

      const newData = {
        labels: labels,
        datasets: [
          {
            label: "Prodi",
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
          },
        ],
      };

      dispatch({
        type: SET_DETAIL_JUMLAH_MAHASISWA_CHART_SUCCESS,
        payload: newData,
      });
    } catch (error) {
      dispatch({
        type: SET_DETAIL_JUMLAH_MAHASISWA_CHART_FAIL,
        payload: error.response.data.message,
      });
      dispatch(setAlert("Can't get data detail chart!", "error"));
    }
  };
